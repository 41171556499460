import React from 'react';
import {IconButton, MenuItem, Tooltip, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {FormikTextField} from '@src/components/FormikFields';
import Dropzone from 'react-dropzone';
import SvgIcon from '@src/components/SvgIcon';
import {downloadFromLink} from '@src/utils/file-utils';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import cx from 'classnames';
import {FormikSelect} from '@src/components/FormikFields/FormikSelect';
import {DatePicker} from '@mui/x-date-pickers';

import {paymentMethod, toCurrency} from '../../utils';

import styles from './financialData.module.scss';
export default function FinancialData({values, setFieldValue, formik, errors = formik?.errors, touched = formik?.touched, file, setFile}){
    const {t} = useTranslation();
    const errorContractInitialDay = errors?.contract_initial_day && touched?.contract_initial_day;
    const errorContractFinalDay = errors?.contract_final_day && touched?.contract_final_day;

    const handleDays = (value, field) => {
        value = value.replace(/\D/g, '');
        if (value !== '' && (parseInt(value) < 1 || parseInt(value) > 31)) {
            value = '';
        }
        formik.setFieldValue(field, value);
    };
    const handlerPositive = (value, field) => {
        value = value.replace(/\D/g, '');
        if (value !== '' && (parseInt(value) < 1) || value === 'e') {
            value = '';
        }
        formik.setFieldValue(field, value);
    };

    const handleValueCurrency = (value, field) => {
        value = value.replace(/[^0-9]/g, '');
        formik.setFieldValue(field, value);
    };
    
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <Typography variant='h6' component='h6' mt={1} mb={2}>
                    {t('ACCOUNTS_PAGE.FINANCIAL_DATA')}
                </Typography>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='value_per_user'
                        data-qa='account-value_per_user'
                        formik={formik}
                        onChange={(e) => handleValueCurrency(e.target.value, 'value_per_user')}
                        value={values?.value_per_user > 0 ? toCurrency(values?.value_per_user) : ''}
                        label={t('ACCOUNTS_PAGE.VALUE_PER_ACTIVE_USER')}
                    />
                </div>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        type='number'
                        name='min_users_number'
                        data-qa='account-min_users_number'
                        label={t('ACCOUNTS_PAGE.MINIMUM_NUMBER_OF_ACTIVE_USERS')}
                        formik={formik}
                        onChange={(e) => handlerPositive(e.target.value, 'min_users_number')}

                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        type='number'
                        name='close_invoice'
                        data-qa='account-close_invoice'
                        label={t('ACCOUNTS_PAGE.INVITE_CLOSING_DATE')}
                        formik={formik}
                        value={values?.close_invoice}
                        onChange={(e) => handleDays(e.target.value, 'close_invoice')}
                    />
                </div>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        type='number'
                        name='payment_day'
                        data-qa='account-payment_day'
                        label={t('ACCOUNTS_PAGE.PAY_DAY')}
                        formik={formik}
                        value={values?.payment_day}
                        onChange={(e) => handleDays(e.target.value, 'payment_day')}
                    />
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.datePickItem}>
                    <DatePicker
                        name='contract_initial_day'
                        data-qa='account-contract_initial_day'
                        value={values?.contract_initial_day}
                        onChange={(date) => setFieldValue('contract_initial_day', date)}
                        label={t('ACCOUNTS_PAGE.START_DATE_OF_CONTRACT_VALIDITY')}
                        className={cx(styles.datePick, {
                            [styles.pickError]: errorContractInitialDay,
                        })}
                    />
                    {errorContractInitialDay && (
                        <span className={styles.errorMessage}>{formik.errors.contract_initial_day}</span>
                    )}
                </div>
                <div className={styles.datePickItem}>
                    <DatePicker
                        value={values?.contract_final_day}
                        data-qa='account-contract_final_day'
                        onChange={(date) => setFieldValue('contract_final_day', date)}
                        label={t('ACCOUNTS_PAGE.END_DATE_OF_THE_CONTRACT_TERM')}
                        className={cx(styles.datePick, {
                            [styles.pickError]: errorContractFinalDay,
                        })}
                    />
                    {errorContractFinalDay && (
                        <span className={styles.errorMessage}>{formik.errors.contract_final_day}</span>
                    )}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='plan'
                        data-qa='account-plan'
                        formik={formik}
                        label={t('ACCOUNTS_PAGE.PLAN')}
                    />
                </div>
                <div className={styles.item}>
                    <FormikSelect
                        id={'payment_method'}
                        name='payment_method'
                        data-qa='account-payment_method'
                        formik={formik}
                        label={t('ACCOUNTS_PAGE.PAYMENT_METHOD')}
                        value={formik?.values.payment_method}
                        onChange={(_, value) => {
                                formik.setFieldValue('payment_method', value.props.value);
                        }}>
                        {paymentMethod.map((i) =>
                            <MenuItem value={i.value} key={i.value}>{t(i.label)}</MenuItem>,
                            )}
                    </FormikSelect>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.item}>
                    <FormikTextField
                        fullWidth
                        name='implantation_value'
                        data-qa='account-implantation_value'
                        label={t('ACCOUNTS_PAGE.IMPLEMENTATION_VALUE')}
                        onChange={(e) => handleValueCurrency(e.target.value, 'implantation_value')}
                        value={toCurrency(formik.values?.implantation_value)}
                        formik={formik}
                    />
                </div>

                <div className={cx(styles.item, styles.contractContainer)}>
                    <UploadComponent
                        error={errors?.contract && touched?.contract && errors?.contract}
                        file={file}
                        contractUrl={values?.contractUrl}
                        setFile={setFile}
                        formik={formik}
                    />
                </div>
            </div>
        </div>
    );
}

const UploadComponent = ({file, setFile, contractUrl, error, formik}) => {
    const {t} = useTranslation();
    const onDrop = async files => {
        setFile(files[0]);
        formik.setFieldValue('contract', file);
    };

    const onRemove = () => {
        setFile(null);
        formik.setFieldValue('contract', null);
    };

    const showUploadMessage = () => {
        if (file) {
            return file.name;
        }
        if (!file && !formik.values.contract) {
            return t('ACCOUNTS_PAGE.INSERT_ATTACHMENT');
        }
        if (!file && formik.values.contract)  {
            return t('ACCOUNTS_PAGE.UPDATE_ATTACHMENT');
        }
        return t('ACCOUNTS_PAGE.INSERT_ATTACHMENT');
    };

    return (
        <div className={styles.uploadContent}>
            <div className={styles.uploadSurface}>
                <Dropzone onDrop={onDrop} accept={acceptedFiles} maxFiles={1}>
                    {({getRootProps, getInputProps}) => (
                        <div className={styles.uploadContainer} {...getRootProps()}>
                            <div className={styles.uploadClick}>
                                <SvgIcon name='UploadSimple' className={styles.uploadIcon} />
                                {showUploadMessage()}
                                <input {...getInputProps()} />
                            </div>
                        </div>
                            )}
                </Dropzone>
                <div className={styles.uploadButtons}>
                    {contractUrl && (
                        <IconButton
                            onClick={() => downloadFromLink(contractUrl)}
                        >
                            <DownloadIcon size={32} />
                        </IconButton>
                    )}

                    {(file || formik.values.contract) && (
                        <IconButton
                            type='button'
                            color='secondary'
                            onClick={onRemove}
                        >
                            <CloseIcon size={32} />
                        </IconButton>
                    )}
                </div>
                <Tooltip
                    className={styles.tooltip}
                    title={t('ACCOUNTS_PAGE.CONTRACT_DISCLAIMER')}
                    >
                    <InfoOutlinedIcon />
                </Tooltip>
            </div>
            {error && <span className={styles.error}>{error}</span>}
        </div>
    );
};

const acceptedFiles = {
    'application/pdf': [],
};

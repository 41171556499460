import * as React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useGetAccountsQuery} from '@src/modules/accounts/accounts.api';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

export function AccountsSelect({disabled, formik}) {
    const {data: accountsData} = useGetAccountsQuery();
    const {t} = useTranslation();

    const accountsOptions = useMemo(() => {
        if (!accountsData) {
            return [];
        }
        return accountsData
            .filter((account) => account?.id !== 'undefined')
            .map((account) => ({
                name: account.name,
                id: account.id,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [accountsData]);

    const selectedAccounts = useMemo(() => {
        if (!formik?.values?.allowedAccounts || !accountsOptions.length) {
            return [];
        }
        return formik.values.allowedAccounts
            .map((id) => accountsOptions.find((account) => account.id === id))
            .filter(Boolean);
    }, [formik.values.allowedAccounts, accountsOptions]);

    function handleOnChange(_, newValue) {
        const ids = newValue.map((option) => option.id);
        formik.setFieldValue('allowedAccounts', ids);
    }
    
    const filteredAccountsOptions = useMemo(() => {
        if (!selectedAccounts.length) {
            return accountsOptions;
        }
        return accountsOptions.filter(
            (account) => !selectedAccounts.some((selected) => selected.id === account.id),
        );
    }, [accountsOptions, selectedAccounts]);

    return (
        <Autocomplete
            multiple
            value={selectedAccounts}
            onChange={handleOnChange}
            options={filteredAccountsOptions}
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            disabled={disabled}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => {
                    const {key, ...tagProps} = getTagProps({index});
                    return (
                        <Chip
                            key={option.id}
                            label={option.name}
                            {...tagProps}
                        />
                    );
                })
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={t('TRAININGS_PAGE.ACCOUNT')}
                    variant='outlined'
                />
            )}
        />
    );
}

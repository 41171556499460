import {accountPayingType, accountStatus} from '../accounts/schema';

export const permissions = [
    {
        name: 'boletos',
        title: 'ADMINS.BOLETOS',
        onlyManage: true,
    },
    {
        name: 'admins',
        title: 'ADMINS.MANAGERS',
        onlyManage: true,
    }, {
        name: 'tutorials',
        title: 'ADMINS.TUTORIALS',
        onlyManage: true,
    }, {
        name: 'notes',
        title: 'ADMINS.NOTES',
        onlyManage: true,
    }, {
        name: 'faq',
        title: 'ADMINS.FAQ',
        onlyManage: true,
    },
    {
        name: 'trainings',
        title: 'ADMINS.TRAININGS',
        onlyManage: false,
    }, {
        name: 'trainingAccounts',
        title: 'ADMINS.TRAINING_ACCOUNTS',
        onlyManage: false,
    }, {
        name: 'commercialAccounts',
        title: 'ADMINS.COMMERCIAL_ACCOUNTS',
        onlyManage: false,
    }, {
        name: 'generalDashboard',
        title: 'ADMINS.DASHBOARD',
        onlyManage: true,
    }, {
        name: 'history',
        title: 'ADMINS.HISTORY',
        onlyManage: true,
    }];

export function formatPermissionValue(permissions) {
    const withoutConsult = permissions.includes('consult')
        ? [...permissions.filter(p => p !== 'consult'), 'list', 'detail']
        : permissions;

    return withoutConsult.length > 0 ? withoutConsult.join(', ') : undefined;
}
export const translateHistoryPath = {
    '/timezone': {translation: 'ACCOUNTS_PAGE.FORM.TIMEZONE', type: 'text'},
    '/created_at': {translation: 'ACCOUNTS_PAGE.CREATED_AT', type: 'date'},
    '/updated_at': {translation: 'ACCOUNTS_PAGE.UPDATED_AT', type: 'date'},
    '/cnpj': {translation: 'ACCOUNTS_PAGE.CNPJ', type: 'text'},
    '/close_invoice': {translation: 'ACCOUNTS_PAGE.INVITE_CLOSING_DATE', type: 'text'},
    '/type': {translation: 'ACCOUNTS_PAGE.TYPE', type: 'text'},
    '/is_demo': {translation: 'ACCOUNTS_PAGE.DEMO', type: 'text'},
    '/max_users': {translation: 'ACCOUNTS_PAGE.MAX_NUMBER_USERS', type: 'number'},
    '/financial_responsible': {translation: 'ACCOUNTS_PAGE.FINANCIAL_RESPONSIBLE', type: 'text'},
    '/birthday': {translation: 'ACCOUNTS_PAGE.DATE_OF_BIRTH', type: 'date'},
    '/name': {translation: 'ACCOUNTS_PAGE.NAME', type: 'text'},
    '/cpf': {translation: 'ACCOUNTS_PAGE.CPF', type: 'cpf'},
    '/phone': {translation: 'ACCOUNTS_PAGE.TELEPHONE', type: 'text'},
    '/email': {translation: 'ACCOUNTS_PAGE.EMAIL', type: 'text'},
    '/payment_day': {translation: 'ACCOUNTS_PAGE.PAY_DAY', type: 'number'},
    '/responsible': {translation: 'ACCOUNTS_PAGE.RESPONSIBLE_DATA', type: 'text'},
    '/disabled': {translation: 'ACCOUNTS_PAGE.ACCOUNT_DISABLED', type: 'text'},
    '/address': {translation: 'ACCOUNTS_PAGE.FULL_ADDRESS', type: 'text'},
    '/number': {translation: 'ACCOUNTS_PAGE.NUMBER', type: 'number'},
    '/city': {translation: 'ACCOUNTS_PAGE.CITY', type: 'text'},
    '/additional_info': {translation: 'ACCOUNTS_PAGE.COMPLEMENT', type: 'text'},
    '/street': {translation: 'ACCOUNTS_PAGE.PUBLIC_PLACE', type: 'text'},
    '/neighborhood': {translation: 'ACCOUNTS_PAGE.NEIGHBORHOOD', type: 'text'},
    '/state': {translation: 'ACCOUNTS_PAGE.STATE', type: 'text'},
    '/postal_code': {translation: 'ACCOUNTS_PAGE.CEP', type: 'text'},
    '/username': {translation: 'ACCOUNTS_PAGE.USERNAME', type: 'text'},
    '/created_by': {translation: 'ACCOUNTS_PAGE.ADMIN_INFORMATION', type: 'text'},
    '/modules': {translation: 'ACCOUNTS_PAGE.TABS.MODULES', type: 'text'},
    '/climateCheck': {translation: 'ACCOUNTS_PAGE.ENABLE_CLIMATE_CHECK', type: 'text'},
    '/value_per_user': {translation: 'ACCOUNTS_PAGE.VALUE_PER_ACTIVE_USER', type: 'currency'},
    '/company_name': {translation: 'ACCOUNTS_PAGE.SOCIAL_NAME', type: 'text'},
    '/subdomain': {translation: 'ACCOUNTS_PAGE.FORM.SUBDOMAIN', type: 'text'},
    '/min_users_number': {translation: 'ACCOUNTS_PAGE.MINIMUM_NUMBER_OF_ACTIVE_USERS', type: 'number'},
    '/contract_initial_day': {translation: 'ACCOUNTS_PAGE.START_DATE_OF_CONTRACT_VALIDITY', type: 'date'},
    '/contract_final_day': {translation: 'ACCOUNTS_PAGE.END_DATE_OF_THE_CONTRACT_TERM', type: 'date'},
    '/status': {translation: 'ACCOUNTS_PAGE.STATUS', type: 'text'},
    '/business_name': {translation: 'ACCOUNTS_PAGE.BUSINESS_NAME', type: 'text'},
    '/plan': {translation: 'ACCOUNTS_PAGE.PLAN', type: 'text'},
    '/payment_method': {translation: 'ACCOUNTS_PAGE.PAYMENT_METHOD', type: 'text'},
    '/financial_responsible/email': {translation: 'ACCOUNTS_PAGE.RESPONSIBLE_FINANCIAL_EMAIL', type: 'text'},
    '/financial_responsible/cpf': {translation: 'ACCOUNTS_PAGE.CPF', type: 'cpf'},
    '/financial_responsible/name': {translation: 'ACCOUNTS_PAGE.RESPONSIBLE_FINANCIAL_NAME', type: 'text'},
    '/financial_responsible/phone': {translation: 'ACCOUNTS_PAGE.RESPONSIBLE_FINANCIAL_PHONE', type: 'text'},
    '/financial_responsible/birthday': {translation: 'ACCOUNTS_PAGE.DATE_OF_BIRTH', type: 'date'},
    '/address/additional_info': {translation: 'ACCOUNTS_PAGE.COMPLEMENT', type: 'text'},
    '/implantation_value': {translation: 'ACCOUNTS_PAGE.IMPLEMENTATION_VALUE', type: 'currency'},
    '/modules/climateCheck': {translation: 'ACCOUNTS_PAGE.ENABLE_CLIMATE_CHECK', type: 'text'},
    '/address/postal_code': {translation: 'ACCOUNTS_PAGE.CEP', type: 'text'},
    '/address/state': {translation: 'ACCOUNTS_PAGE.STATE', type: 'text'},
    '/address/neighborhood': {translation: 'ACCOUNTS_PAGE.NEIGHBORHOOD', type: 'text'},
    '/address/street': {translation: 'ACCOUNTS_PAGE.PUBLIC_PLACE', type: 'text'},
    '/address/city': {translation: 'ACCOUNTS_PAGE.CITY', type: 'text'},
    '/address/number': {translation: 'ACCOUNTS_PAGE.NUMBER', type: 'number'},
};

export const translateHistoryKey = {
    'birthday': {translation: 'ACCOUNTS_PAGE.DATE_OF_BIRTH', type: 'date'},
    'name': {translation: 'ACCOUNTS_PAGE.NAME', type: 'text'},
    'cpf': {translation: 'ACCOUNTS_PAGE.CPF', type: 'cpf'},
    'phone': {translation: 'ACCOUNTS_PAGE.RESPONSIBLE_FINANCIAL_PHONE', type: 'text'},
    'email': {translation: 'ACCOUNTS_PAGE.RESPONSIBLE_FINANCIAL_EMAIL', type: 'text'},
    'payDay': {translation: 'ACCOUNTS_PAGE.PAY_DAY', type: 'number'},
    'number': {translation: 'ACCOUNTS_PAGE.NUMBER', type: 'number'},
    'city': {translation: 'ACCOUNTS_PAGE.CITY', type: 'text'},
    'additional_info': {translation: 'ACCOUNTS_PAGE.COMPLEMENT', type: 'text'},
    'street': {translation: 'ACCOUNTS_PAGE.PUBLIC_PLACE', type: 'text'},
    'neighborhood': {translation: 'ACCOUNTS_PAGE.NEIGHBORHOOD', type: 'text'},
    'state': {translation: 'ACCOUNTS_PAGE.STATE', type: 'text'},
    'postal_code': {translation: 'ACCOUNTS_PAGE.CEP', type: 'text'},
    'fullAddress': {translation: 'ACCOUNTS_PAGE.FULL_ADDRESS', type: 'text'},
    'climateCheck': {translation: 'ACCOUNTS_PAGE.ENABLE_CLIMATE_CHECK', type: 'text'},
};

export const translateHistoryValue = {
    [accountStatus.READY]: 'ACCOUNTS_PAGE.READY',
    [accountStatus.PREPARING]: 'ACCOUNTS_PAGE.PREPARING',
    [accountPayingType.FREE]: 'ACCOUNTS_PAGE.FREE',
    [accountPayingType.PAID]: 'ACCOUNTS_PAGE.PAY',
    [accountPayingType.EXPERIMENTAL]: 'ACCOUNTS_PAGE.EXPERIMENTAL',
    'credit_card': 'ACCOUNTS_PAGE.CREDIT',
    'boleto_bancario': 'ACCOUNTS_PAGE.BOLETO',
    'pix': 'ACCOUNTS_PAGE.PIX',
    'true': 'ACCOUNTS_PAGE.YES',
    'false': 'ACCOUNTS_PAGE.NO',

};

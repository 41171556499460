import {FormControl} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import * as moment from 'moment';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './audit-table-search.module.scss';

export function AuditTableSearch({dateFrom, setPage, dateTo, setDateFrom, setDateTo}) {
    const {t} = useTranslation();
    const [isFromOpen, setIsFromOpen] = useState(false);
    const [isToOpen, setIsToOpen] = useState(false);

    function handleDateTo(date) {
        setDateTo(date);
        setPage(0);
    }

    function handleDateFrom(date) {
        setDateFrom(date);
        setPage(0);
    }
    
    return (
        <div className={styles.filters}>
            <FormControl>
                <DatePicker
                    value={dateFrom}
                    onChange={handleDateFrom}
                    id='from'
                    label={t('ACCOUNT_DASHBOARD_PAGE.FROM')}
                    maxDate={moment(dateTo).startOf('day')}
                    sx={{
                        width: 150,
                    }}
                    open={isFromOpen}
                    onOpen={() => setIsFromOpen(true)}
                    onClose={() => setIsFromOpen(false)}
                    slotProps={{
                        textField: {
                            inputProps: {
                                readOnly: true,
                            },
                            onClick: () => setIsFromOpen(true),
                        },
                    }}
            />
            </FormControl>

            <FormControl>
                <DatePicker
                    value={dateTo}
                    onChange={handleDateTo}
                    id='to'
                    label={t('ACCOUNT_DASHBOARD_PAGE.TO')}
                    minDate={moment(dateFrom).endOf('day')}
                    sx={{
                        width: 150,
                    }}
                    open={isToOpen}
                    onOpen={() => setIsToOpen(true)}
                    onClose={() => setIsToOpen(false)}
                    slotProps={{
                        textField: {
                            inputProps: {
                                readOnly: true,
                            },
                            onClick: () => setIsToOpen(true),
                        },
                    }}
            />
            </FormControl>
        </div>
    );
}

import React, {createContext, useMemo, useState} from 'react';
import Table from '@src/components/Table';
import {useTranslation} from 'react-i18next';
import * as moment from 'moment';
import {Button} from '@mui/material';

import {AuditDialog} from '../AuditDialog';

import styles from './audit-table.module.scss';

const AuditTableContext = createContext({});

export function AuditTable({data, isLoading, setPage, pageSize, page, isError, updateList}) {
    const {t} = useTranslation();
    const [id, setId] = useState(null);

    const columns = useMemo(() => [
        {accessor: 'name', Header: t('ACCOUNTS_PAGE.AUDIT_HISTORY.NAME')},
        {accessor: 'email', Header: t('ACCOUNTS_PAGE.AUDIT_HISTORY.EMAIL')},
        {accessor: 'changeDate', Header: t('ACCOUNTS_PAGE.AUDIT_HISTORY.CHANGE_DATE')},
        {accessor: 'changeCount', Header: t('ACCOUNTS_PAGE.AUDIT_HISTORY.NUMBER_CHANGES')},
    ], [t]);

    const rows = useMemo(() => {
        if (!data) {
            return [];
        }

        return [...data]
            .filter((row) => {
                if (row.diffs.length === 0) {
                    return false;
                }
                const hasExcludedPaths = row.diffs.some((diff) => {
                    if (diff.path === '/logo_key') {
                        return !(diff.value && diff.value.startsWith('unconfirmed'));
                    }
                    if (diff.path === '/contract_key') {
                        return !(diff.value && diff.value.startsWith('unconfirmed'));
                    }
                    return  diff.path === '/colors' || diff.path === '/lang';
                });
                
                return !hasExcludedPaths;
            })
            .sort((a, b) => moment(b.created_at) - moment(a.created_at))
            .map((row) => ({
                id: row.id,
                name: row?.name,
                email: row?.email,
                changeDate: moment(row.change_date).format('DD/MM/YYYY HH:mm:ss'),
                createdAt: moment(row.created_at).format('DD/MM/YYYY HH:mm:ss'),
                changeCount: row?.changeCount,
                diffs: row?.diffs,
            }));
    }, [data]);

    const paginateArray = (array, pageSize, pageNumber) => {
        const startIndex = (pageNumber) * pageSize;
        const endIndex = startIndex + pageSize;
        return array.slice(startIndex, endIndex);
    };

    return (
        <AuditTableContext.Provider value={{rows, id, setId}}>
            <Table
                columns={columns}
                isError={isError}
                data={paginateArray(rows, pageSize, page)}
                notFoundText={t('ACCOUNTS_PAGE.AUDIT_HISTORY.NO_CHANGES_FOUND')}
                Actions={(props) => <Actions {...props} updateList={updateList} />}
                actionsClassName={styles.actions}
                loading={isLoading}
                setPage={setPage}
                pageSize={pageSize}
                total={rows?.length}
                pageActual={page}
            />
        </AuditTableContext.Provider>
    );
}

const Actions = ({data}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const {t} = useTranslation();
    function onClose() {
        setOpenDialog(false);
    }
    
    return (
        <div className={styles.actions}>
            <Button onClick={() => setOpenDialog(true)}>{t('ACCOUNTS_PAGE.VIEW_CHANGES')}</Button>
            <AuditDialog row={data || []} open={openDialog} onClose={onClose} />
        </div>
    );

};

import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {useListAllHistoryQuery} from '@src/modules/accounts/accounts.api';
import * as moment from 'moment';

import {AuditTable} from '../../AuditTable';
import {AuditTableSearch} from '../../AuditTableSearch';

export function AuditTab() {
    const [page, setPage] = useState(0);
    const {id} = useParams();
    const [dateFrom, setDateFrom] = useState(moment().subtract(7, 'days').startOf('day'));
    const [dateTo, setDateTo] = useState(moment().endOf('day'));

    const {data: listHistory, isLoading, isError, refetch: updateList} = useListAllHistoryQuery({
        id,
        from: dateFrom.toISOString(),
        to: dateTo.toISOString(),
    }, {refetchOnMountOrArgChange: true});

    return (
        <>
            <AuditTableSearch 
                dateFrom={dateFrom}
                dateTo={dateTo}
                setPage={setPage}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
            />
            <AuditTable
                isError={isError}
                data={listHistory}
                isLoading={isLoading}
                setPage={setPage}
                page={page}
                pageSize={10}
                updateList={updateList}
            />
        </>
    );
}

import {ChevronRight} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {translateHistoryKey, translateHistoryPath, translateHistoryValue} from '@src/modules/admins/utils';
import * as moment from 'moment';
import cx from 'classnames';
import {formatCPF} from '@brazilian-utils/brazilian-utils';
import {toCurrency} from '@src/modules/accounts/utils';

import styles from './audit-dialog.module.scss';

export function AuditDialog({open, onClose, className, row}) {
    const {t} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            className={cx({[className]: className}, styles.dialogContent)}
    >
            <DialogTitle id='alert-dialog-title'>
                {t('ACCOUNTS_PAGE.AUDIT_HISTORY.AUDIT_DETAILS')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description' width={480}>
                    {t('ACCOUNTS_PAGE.AUDIT_HISTORY.AUDIT_DETAILS_DESCRIPTION',
                        {
                            date: row?.changeDate,
                            name: row?.name,
                            interpolation: {
                                escapeValue: false, 
                            },
                        },
                    )}
                </DialogContentText>
                <Divider className={styles.divider} />
                <AuditDetailsChanges changes={row?.diffs} />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('ACCOUNTS_PAGE.AUDIT_HISTORY.CLOSE')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export function AuditDetailsChanges({changes}) {
    const {t} = useTranslation();

    return changes
        .map((change, index) => {
            if (change?.value && typeof change?.value === 'object') {
                return  <AuditDetailsObject change={change} key={index} />;
            }
            if (change?.path === '/contract_key') {
                return <div key={index}>{t('ACCOUNTS_PAGE.CONTRACT_SUCCESS')}</div>;
            }
            if (change?.path === '/logo_key') {
                return <div key={index}>{t('ACCOUNTS_PAGE.CONTRACT_LOGO_SUCCESS')}</div>;
            }
            if (change?.path === '/password') {
                return <div key={index}>{t('ACCOUNTS_PAGE.PASSWORD_SUCCESS')}</div>;
            }
            return (
                <div key={index} className={styles.changeRow}>
                    <span className={styles.fieldName}>
                        {t(translateHistoryPath[change?.path]?.translation) || change?.path}:
                    </span>
                    <span className={styles.oldValue}>
                        {formatValue(change?.oldValue, t, translateHistoryPath[change?.path]?.type, change.op !== 'add')}
                    </span>
                    {change?.op !== 'add' && (
                        <div className={styles.chevronContent}>
                            <ChevronRight className={styles.chevron} />
                        </div>
                )}
                    <div>
                        <div className={styles.newValue}>
                            {formatValue(change?.value, t, translateHistoryPath[change?.path]?.type)}
                        </div>
                    </div>
                </div>
            );
        },
        );
}

function AuditDetailsObject({change}) {
    const {t} = useTranslation();
    
    return (
        <div className={styles.changeRowObject}>
            <div>
                <span className={styles.fieldName}>
                    {t(translateHistoryPath[change?.path]?.translation) || change?.path}:
                </span>
            </div>
            <div>
                {change?.op !== 'add' && (
                    <div>
                        <span className={styles.oldValue}>
                            {formatValue(change?.oldValue, t, translateHistoryPath[change?.path]?.type, false)}
                        </span>
                        <div className={styles.chevronContent}>
                            <ChevronRight className={styles.chevron} />
                        </div>
                    </div>
            )}
                <div className={styles.newValueObject}>
                    {formatValue(change?.value, t, translateHistoryPath[change?.path]?.type)}
                </div>
            </div>
            {Object.entries(change?.value)
                .map(([key, val]) => {
                    return (
                        <div key={key}>
                            <span className={styles.fieldNameObject}>
                                • {t(translateHistoryKey[key]?.translation) || key}:
                            </span>
                            <span className={styles.newValueObject}>
                                {formatValue(val, t, translateHistoryKey[key]?.type) || val}  
                            </span>
                        </div>
                    );
                })
            }
        </div>
    );
}

function formatValue(value, t, type, showEmptyMessage = true) {
    if (showEmptyMessage && (value === null || value === undefined)) {
        return <span className={styles.emptyField}>Campo vazio</span>;
    }
    if (typeof value === 'object') {
        return;
    }
    if (type === 'cpf') {
        return formatCPF(value);
    }
    if (value && type === 'date') {
        return moment(value).format('DD/MM/YYYY');
    }
    if (value && type === 'currency') {
        return toCurrency(value);
    }
  
    return t(translateHistoryValue[value]) || value;
}
